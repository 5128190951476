





































import { debounceProcess } from "@/helpers/debounce";
import MNotificationVue from "@/mixins/MNotification.vue";
import {
  ContactData,
  ResponseListContactData,
} from "@interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { IOption } from "@interface/common.interface";
import Vue from "vue";
import { contactServices } from "@service/contact.service";
import { DEFAULT_PAGE_SIZE } from "@/models/constant/global.constant";

export default Vue.extend({
  name: "CSelectCustomer",
  mixins: [MNotificationVue],
  props: {
    value: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    propCustomerName: {
      type: String,
      default: undefined,
    },
    forList: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    this.searchCustomer = debounceProcess(this.searchCustomer, 300);
    return {
      loading: false,
      optCustomer: [] as IOption<ContactData>[],
      queryParams: {
        page: 0,
        limit: DEFAULT_PAGE_SIZE,
        search: "customer~true_AND_active~true",
        sorts: "firstName:asc",
      },
      dtCustomers: {} as ResponseListContactData,
    };
  },
  watch: {
    propCustomerName(newVal) {
      if (newVal) {
        this.handleMissingOpts();
      }
    },
  },
  created() {
    this.getList(this.queryParams);
    this.handleMissingOpts();
  },
  methods: {
    getListCustomer(
      params: RequestQueryParamsModel
    ): Promise<ResponseListContactData> {
      return contactServices.listContactData(params);
    },
    onSelect(e: string, meta: IOption<ContactData>): void {
      this.$emit("input", e);
      this.$emit("on-select", { value: e, meta });
    },
    searchCustomer(search = ""): void {
      const searchBy: string[] = ["customer~true_AND_active~true"];
      let findBy = "";
      if (search) {
        searchBy.unshift(`firstName~*${search}*_OR_lastName~*${search}*`);
        findBy = searchBy.join("_AND_");
      } else {
        findBy = searchBy.join();
      }
      this.queryParams.page = 0;
      this.queryParams.search = findBy;
      this.optCustomer = [];
      this.getList(this.queryParams);
    },
    popupScroll(e): void {
      if (this.dtCustomers.totalPages - 1 === this.dtCustomers.currentPage)
        return;
      const target = e.target;
      if (target.scrollTop + target.offsetHeight === target.scrollHeight) {
        this.queryParams.page += 1;
        this.getList(this.queryParams);
      }
    },
    async getList(params: RequestQueryParamsModel): Promise<void> {
      try {
        this.loading = true;
        const res = await this.getListCustomer(params);
        const { optCustomer } = this;
        const opts = res.data.map((x) => ({
          key: x.fullName,
          value: x.id,
          meta: x,
        }));
        this.optCustomer = [...optCustomer, ...opts];
        this.dtCustomers = res;
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    async handleMissingOpts(): Promise<void> {
      try {
        if (this.forList || !this.value) return;
        const opt = this.optCustomer.find((x) => x.value === this.value);
        if (!opt) {
          const { data } = await this.getListCustomer({
            search: `secureId~${this.value}`,
          });
          const newOpt = {
            key: data[0].fullName,
            value: data[0].id,
            meta: data[0],
          };
          const { optCustomer } = this;
          this.optCustomer = [...optCustomer, newOpt];
        }
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      }
    },
    clear(): void {
      this.$emit("input", undefined);
      this.$emit("on-clear", { value: "", meta: undefined });
    },
    onChange(e: string): void {
      this.$emit("change", e);
    },
  },
});
